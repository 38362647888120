import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import namelogo from "../assets/img/name_logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={20}>
            <img src={namelogo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/khayamu"><img src={navIcon1} alt="" /></a>
              <a href="https://github.com/khayamumair"><img src={navIcon2} alt="" /></a>
            </div>
            <p>© Khayam Umair 2023</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
