import React from 'react';
import Masonry from 'react-masonry-css';
import './ArtGallery.css';
import IMG_0829 from "../assets/img/IMG_0829.JPG";
import IMG_1145 from "../assets/img/IMG_1145.JPG";
import IMG_1264 from "../assets/img/IMG_1264.JPG";
import IMG_3313 from "../assets/img/IMG_3313.jpg";
import IMG_3701 from "../assets/img/IMG_3701.JPG";
import IMG_3743 from "../assets/img/IMG_3743.jpg";
import IMG_3962 from "../assets/img/IMG_3962.JPG";
import IMG_4114 from "../assets/img/IMG_4114.JPG";
import IMG_4826 from "../assets/img/IMG_4826.jpg";
import IMG_5462 from "../assets/img/IMG_5462.JPG";
import IMG_5545 from "../assets/img/IMG_5545.JPG";
import IMG_8132 from "../assets/img/IMG_8132.jpg";
import IMG_4226 from "../assets/img/IMG_4226.JPG";
import IMG_4239 from "../assets/img/IMG_4239.JPG";
import IMG_4272 from "../assets/img/IMG_4272.JPG";
import IMG_5555 from "../assets/img/IMG_5555.JPG";
import IMG_6969 from "../assets/img/IMG_6969.JPG";

const images = [
    IMG_0829,
    IMG_3701,
    IMG_1264,
    IMG_5555,
    IMG_3313,
    IMG_1145,
    IMG_3743,
    IMG_3962,
    IMG_6969,
    IMG_4272,
    IMG_4114,
    IMG_4826,
    IMG_5462,
    IMG_5545,
    IMG_8132,
    IMG_4226,
    IMG_4239
];

const breakpointColumnsObj = {
  default: 4,  // 3 columns for large screens
  1100: 3,     // 3 columns for medium screens
  700: 2,      // 2 columns for small screens
  500: 2       // 1 column for extra small screens
};

export const ArtGallery = () => {
  return (
    <div id="art-gallery">
        <h2>My Art Gallery</h2>
        <p>Here are some of my favorite pieces that I've created over the years:</p>
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {images.map((image, index) => (
        <div key={index}>
          <img src={image} alt={`Artwork ${index + 1}`} />
        </div>
      ))}
    </Masonry>
    </div>
  );
};

export default ArtGallery;
