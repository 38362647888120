import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import undergradDiss from "../assets/img/undergradDiss.png";
import skateboardCollage from "../assets/img/skateboardCollage.png";
import pingshackPic from "../assets/img/pingshackPic.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import React, { useState, useEffect } from "react";

export const Projects = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const projects = [
    {
      title: "Electric Skateboard",
      description: "Utilised a 180kV brushless motor connected to an ESC along with a radio transmitter for remote control. Fully functional charging system, battery indicator and modularity. Final results after testing displayed a 14mph maximum speed with an estimated 5 miles range.",
      imgUrl: skateboardCollage,
    },
    {
      title: (
        <a href="https://www.pingshack.com/" target="_blank" rel="noopener noreferrer">
          Pingshack (Shacksolutions)
        </a>
      ),
      description: "Business Startup",
      imgUrl: pingshackPic,
    },      
    {
      title: (
        <a href="https://drive.google.com/file/d/1jzYaX-BsHmP01FK_ocTuMmexf0W6ukPe/view?usp=sharing" download>
          Micromagnetic Modelling Project
        </a>
      ),
      description: "Undergraduate Project",
      imgUrl: undergradDiss,
    },    
  ];

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <div className="animate__animated animate__fadeIn">
              <h2>Projects</h2>
              <p>A few highlights of my personal projects (at the moment):</p>
              <Tab.Container id="projects-tabs" defaultActiveKey="first">
                <Tab.Content id="slideInUp">
                  <Tab.Pane eventKey="first">
                    {isMobile ? (
                      <Row className="animate__animated animate__fadeIn">
                        {projects.map((project, index) => {
                          return (
                            <ProjectCard
                              key={index}
                              {...project}
                              />
                          )
                        })}
                      </Row>
                    ) : (
                      <TrackVisibility>
                        {({ isVisible }) =>
                          <Row className={isVisible ? "animate__animated animate__fadeIn" : "animate__animated animate__fadeOut"}>
                            {
                              projects.map((project, index) => {
                                return (
                                  <ProjectCard
                                    key={index}
                                    {...project}
                                    />
                                )
                              })
                            }
                          </Row>
                        }
                      </TrackVisibility>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}  