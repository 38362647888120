import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import headerImg from "../assets/img/header-img.svg";
import headerImg from "../assets/img/me_as_a_kid_logo_circle.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import './Banner.css';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  // const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [delta, setDelta] = useState(200 - Math.random() * 50); // Change this line
  const [index, setIndex] = useState(1);
  const toRotate = [ "AI Student & Electronic Engineering Grad." ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(50); // Backspace Speed
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period / 2);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(200 - Math.random() * 50); // Typing Speed
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility once>
              {({ isVisible }) =>
              <div>
                <span className="tagline">Welcome to my Portfolio!</span>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h1 className="name-title">{`Khayam Umair:`}</h1>
                  <h1>
                    <span 
                      className="txt-rotate" 
                      dataPeriod="1000" 
                      data-rotate='[ "AI Student & Electronic Engineering Grad." ]'
                      style={{ 
                        display: 'inline-block', 
                        overflow: 'hidden', 
                        height: '2.2em', // Adjust this value according to your font size
                      }}>
                      <span className="wrap">{text}</span>
                    </span>
                  </h1>
                </div>
                <p className="about-me-text">
                  An MSc Artificial Intelligence Student at the University of St Andrews, 
                  BEng Electronic Engineering Graduate from Royal Holloway, 
                  University of London and Graduate from Eton College. 
                  Recently working as a Data Engineer dedicated to Data, Analytics & AI.
                </p>
                <button onClick={() => {document.getElementById('about').scrollIntoView({behavior: "smooth"});}}>See more <ArrowRightCircle size={25} /></button>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility once>
              {({ isVisible }) =>
                <div className={isVisible ? "" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}