import python from "../assets/img/Python-logo-notext.png";
import java from "../assets/img/Java_programming_language_logo.png";
import cplusplus from "../assets/img/ISO_C++_Logo.png";
import matlab from "../assets/img/Matlab_Logo.png";
import pytorch from "../assets/img/PyTorch_logo_icon.png";
import git from "../assets/img/Git_icon.png";
import azure from "../assets/img/Microsoft_Azure.png";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"
import colorSharp2 from "../assets/img/color-sharp2.png";
import './Skills.css';
import aboutMePic from "../assets/img/about_me_pic.png";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="about">
        <div className="container">
            <div className="row">
            <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <div className="row align-items-center">
                          <div className="col-12 d-flex justify-content-center align-items-center">
                              <h2 className="mr-3">About me</h2>
                              <img src={aboutMePic} className="rounded-circle about-me-img" alt="About Me" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                              <p className="about-me-paragraph">I'm Khayam Umair, a recent graduate of Royal Holloway, University of London, and a proud alumnus of Eton College. My education path has been marked by diversity and a consistent interest in technology. Currently, I am continuing my learning journey by pursuing a MSc in Artificial Intelligence at the University of St. Andrews.</p>

                              <p className="about-me-paragraph">With an extensive background in electronic engineering and programming, and a budding career in AI, I thrive in environments where innovative solutions and calculated risk-taking are valued.</p>

                              <p className="about-me-paragraph">I’m fuelled by curiosity. I am passionate about a wide array of subjects ranging from Physics and Mathematics to Art and Spanish. I believe that my multidisciplinary interests allow me to approach problem-solving from various angles, thus fostering creativity and innovative thinking in all that I aspire to do.</p>

                              <p className="about-me-paragraph">In my personal time, I thoroughly enjoy delving into the ever-evolving world of AI software and experimenting with new tools, constantly striving to expand my technical skills and knowledge. Music also plays a significant role in my life, as I play the guitar and violin, each instrument providing a unique outlet for creativity and discipline.</p>
                          </div>
                          <div className="col-md-6">
                              <p className="about-me-paragraph">Art, in its myriad forms, captivates me - from traditional sketches and paintings to cutting-edge AI-generated designs. The act of creating something from nothing, whether on canvas or through a machine learning algorithm, is an immensely rewarding process that has taught me patience, precision, and the value of perspective. I have had a passion for art and entrepreneurship since a young age and have co-founded Pingshack, a thriving online community that I implore you to discover from this page.</p>

                              <p className="about-me-paragraph">An inclination towards hands-on projects led me to construct my own electric longboard, a venture that tested and expanded my engineering skills, whilst combining functionality and creativity. This can be explored below in the Projects section.</p>

                              <p className="about-me-paragraph">On the physical side of things, I am an ardent football and badminton player and have a keen interest in skiing. These sports foster my teamwork, leadership skills, and resilience while providing a refreshing diversion.</p>

                              <p className="about-me-paragraph">Currently, I'm working towards my MSc in Artificial Intelligence and am seeking to apply my knowledge in real-world scenarios. You can explore some of my projects and skills down below or on my Github (in progress :/) or read one my academic papers also on this page. I'm always open to connecting with fellow tech enthusiasts, so feel free to shoot me an email or connect with me on social media!</p>
                          </div>
                        </div>
                        <Carousel 
                            responsive={responsive} 
                            infinite={true} 
                            autoPlay={true} 
                            autoPlaySpeed={2500} 
                            className="owl-carousel owl-theme skill-slider"
                        >
                            <div className="item">
                                <img src={python} alt="Image" />
                                <h5>Python</h5>
                            </div>
                            <div className="item">
                                <img src={java} alt="Image" />
                                <h5>Java</h5>
                            </div>
                            <div className="item">
                                <img src={cplusplus} alt="Image" />
                                <h5>C++</h5>
                            </div>
                            <div className="item">
                                <img src={matlab} alt="Image" />
                                <h5>Matlab</h5>
                            </div>
                            <div className="item">
                                <img src={pytorch} alt="Image" />
                                <h5>PyTorch</h5>
                            </div>
                            <div className="item">
                                <img src={git} alt="Image" />
                                <h5>Git</h5>
                            </div>
                            <div className="item">
                                <img src={azure} alt="Image" />
                                <h5>Azure</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
        <img className="background-image-right" src={colorSharp2} alt="Image" />
    </section>
  )
}
